import React from 'react';
import PropTypes from 'prop-types';

import env from '../../env';
import { urlWrapper } from '../../utils/urlWrapperUtils';
import TargetSelect from '../Utils/TargetSelect';
import Image from '../Image';

import './styles.scss';

/**
 *
 * @visibleName UIEventBox
 */
class UIEventBox extends React.Component {
    static mapApiItemDataToProps(data) {
        const {
            name,
            title,
            start_date: startDate, // eslint-disable-line camelcase
            end_date: endDate, // eslint-disable-line camelcase
            files,
            id,
            language,
        } = data;

        const to = language === 'de' ? 'bis' : 'to';

        let date = '';
        if (startDate) {
            date = startDate;
        }
        if (endDate && startDate !== endDate) {
            date = `${startDate} ${to} ${endDate}`;
        }
        return {
            imgSrc: files && files.picture && files.picture.length > 0 ?
                files.picture[0].url : env.IMG_NOT_FOUND_URL,
            imgAlt: title,
            imgTitle: title,
            url: `/${language}/events/${id}/`,
            headline: title,
            date,
            places: name,
        };
    }

    render() {
        const { isPdf, imgSrc, imgAlt, imgTitle, headline, weekDays, date, time, url, target, places } = this.props;
        const className = 'ui-event-box';
        const linkProps = {
            href: urlWrapper(url),
            target,
            rel: target === '_blank' ? 'noopener noreferrer' : '',
        };

        // "data:image/png;base64,sdfsdf
        const isImageSrcBase64 = Boolean(imgSrc.match(/;base64,/));

        return (
            <a className={`${className} ${isPdf ? 'render-pdf' : ''}`} {...linkProps}>
                <div className={`${className}__content`}>
                    <div className={`${className}__meta`}>
                        {weekDays &&
                            <span className={`${className}__week-days`}>{weekDays}</span>
                        }
                        <span className={`${className}__date`}>{date}</span>
                        {time &&
                            <span className={`${className}__time`}>{time}</span>
                        }
                    </div>
                    <h4 className={`${className}__title`}>{headline}</h4>
                    <p className={`${className}__places`}>{places}</p>
                </div>
                <div className={`${className}__img-container`}>
                    {isImageSrcBase64 &&
                        <div className={`${className}__img-inline`} title={imgTitle}>
                            <img
                                className={`${className}__img-inline`}
                                src={imgSrc}
                                alt={imgAlt}
                                title={imgTitle}
                            />
                        </div>
                    }
                    {!isImageSrcBase64 &&
                        <Image
                            className={`${className}__img`}
                            imgSrc={imgSrc}
                            imgAlt={imgAlt}
                            imgTitle={imgTitle}
                            imgSrcSet={[
                                `${imgSrc} 320w`,
                                `${imgSrc} 500w`,
                                `${imgSrc} 640w`,
                                `${imgSrc} 768w`,
                                `${imgSrc} 900w`,
                                `${imgSrc} 1024w`,
                                `${imgSrc} 1366w`,
                                `${imgSrc} 1536w`,
                                `${imgSrc} 1600w`,
                                `${imgSrc} 1920w`,
                                `${imgSrc} 2048w`,
                                `${imgSrc} 3072w`,
                            ]}
                            imgSizes={
                                '(min-width: 1536px) 491px,' +
                                '(min-width: 1140px) 30.6vw,' +
                                '(min-width: 900px) 29.6vw,' +
                                '(min-width: 600px) 43.7vw, 90.4vw'
                            }
                        />
                    }
                </div>
            </a>
        );
    }
}

UIEventBox.formFields = Object.assign({}, {
    imgSrc: <input />,
    imgAlt: <input />,
    imgTitle: <input />,
    headline: <input />,
    weekDays: <input />,
    date: <input />,
    time: <input />,
    url: <input />,
    places: <input />,
    target: <TargetSelect />,
});

UIEventBox.propTypes = {
    isPdf: PropTypes.bool,
    imgSrc: PropTypes.string,
    imgAlt: PropTypes.string,
    imgTitle: PropTypes.string,
    headline: PropTypes.string,
    weekDays: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
    url: PropTypes.string,
    places: PropTypes.string,
    target: PropTypes.string,
};

UIEventBox.defaultProps = {
    isPdf: false,
    imgSrc: '/images/chris-holgersson-276772-unsplash.jpg',
    imgAlt: 'Default alt',
    imgTitle: 'Default title',
    headline: 'BBQ auf der Furtschellas',
    weekDays: null, // 'Mo - So',
    date: null, // 'Mo | 2. October 2018',
    time: null, // '20:30',
    url: '#',
    places: 'Furtschellas, Sils Maria',
    target: '_self',
};

export default UIEventBox;
