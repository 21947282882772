import React from 'react';
import PropTypes from 'prop-types';

import ItemDataFetcher from '../../api/object-admin/ItemDataFetcher';
import SelectItemType from '../EditForm/Inputs/SelectItemType';
import TargetSelect from '../Utils/TargetSelect';

import UIEventBox from '../UIEventBox';

import './styles.scss';

/**
 * @visibleName 3 Teaser Boxes Events
 */
class EventBoxList extends React.Component {
    render() {
        const {
            itemType1, itemId1, url1, targetUrl1,
            itemType2, itemId2, url2, targetUrl2,
            itemType3, itemId3, url3, targetUrl3,

            imgSrc1, imgAlt1, imgTitle1, headline1, date1, places1,
            imgSrc2, imgAlt2, imgTitle2, headline2, date2, places2,
            imgSrc3, imgAlt3, imgTitle3, headline3, date3, places3,

            language, season,
        } = this.props;
        const className = 'event-box-listing';
        return (
            <div className={className}>
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-col-xl-4 g-col-lg-2 g-col-md-2 g-col-sm-2">
                            {itemType1 ? (
                                <ItemDataFetcher
                                    itemType={itemType1}
                                    itemId={itemId1}
                                    language={language}
                                    season={season}
                                >
                                    <UIEventBox url={url1} target={'_self'} />
                                </ItemDataFetcher>
                            ) : (
                                <UIEventBox
                                    url={url1}
                                    imgSrc={imgSrc1}
                                    imgAlt={imgAlt1}
                                    imgTitle={imgTitle1}
                                    headline={headline1}
                                    date={date1}
                                    places={places1}
                                    target={targetUrl1}
                                />
                            )}
                        </div>

                        <div className="g-col-xl-4 g-col-lg-2 g-col-md-2 g-col-sm-2">
                            {itemType2 ? (
                                <ItemDataFetcher
                                    itemType={itemType2}
                                    itemId={itemId2}
                                    language={language}
                                    season={season}
                                >
                                    <UIEventBox url={url2} target={'_self'} />
                                </ItemDataFetcher>
                            ) : (
                                <UIEventBox
                                    url={url2}
                                    imgSrc={imgSrc2}
                                    imgAlt={imgAlt2}
                                    imgTitle={imgTitle2}
                                    headline={headline2}
                                    date={date2}
                                    places={places2}
                                    target={targetUrl2}
                                />
                            )}
                        </div>

                        <div className="g-col-xl-4 g-col-lg-2 g-offset-md-1 g-col-md-2 g-col-sm-2">
                            {itemType3 ? (
                                <ItemDataFetcher
                                    itemType={itemType3}
                                    itemId={itemId3}
                                    language={language}
                                    season={season}
                                >
                                    <UIEventBox url={url3} target={'_self'} />
                                </ItemDataFetcher>
                            ) : (
                                <UIEventBox
                                    url={url3}
                                    imgSrc={imgSrc3}
                                    imgAlt={imgAlt3}
                                    imgTitle={imgTitle3}
                                    headline={headline3}
                                    date={date3}
                                    places={places3}
                                    target={targetUrl3}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EventBoxList.formFields = Object.assign({}, {
    itemType1: <SelectItemType id={'itemType1'} options={['events']} />,
    itemId1: <input />,
    url1: <input />,
    targetUrl1: <TargetSelect />,

    imgSrc1: <input />,
    imgAlt1: <input />,
    imgTitle1: <input />,
    headline1: <input />,
    date1: <input />,
    places1: <input />,
    //
    itemType2: <SelectItemType id={'itemType2'} options={['events']} />,
    itemId2: <input />,
    url2: <input />,
    targetUrl2: <TargetSelect />,

    imgSrc2: <input />,
    imgAlt2: <input />,
    imgTitle2: <input />,
    headline2: <input />,
    date2: <input />,
    places2: <input />,
    //
    itemType3: <SelectItemType id={'itemType3'} options={['events']} />,
    itemId3: <input />,
    url3: <input />,
    targetUrl3: <TargetSelect />,

    imgSrc3: <input />,
    imgAlt3: <input />,
    imgTitle3: <input />,
    headline3: <input />,
    date3: <input />,
    places3: <input />,
});

EventBoxList.propTypes = {
    // item1
    itemType1: PropTypes.string,
    itemId1: PropTypes.string,
    url1: PropTypes.string,
    targetUrl1: PropTypes.string,

    imgSrc1: PropTypes.string,
    imgAlt1: PropTypes.string,
    imgTitle1: PropTypes.string,
    headline1: PropTypes.string,
    date1: PropTypes.string,
    places1: PropTypes.string,
    // item2
    itemType2: PropTypes.string,
    itemId2: PropTypes.string,
    url2: PropTypes.string,
    targetUrl2: PropTypes.string,

    imgSrc2: PropTypes.string,
    imgAlt2: PropTypes.string,
    imgTitle2: PropTypes.string,
    headline2: PropTypes.string,
    date2: PropTypes.string,
    places2: PropTypes.string,
    // item3
    itemType3: PropTypes.string,
    itemId3: PropTypes.string,
    url3: PropTypes.string,
    targetUrl3: PropTypes.string,

    imgSrc3: PropTypes.string,
    imgAlt3: PropTypes.string,
    imgTitle3: PropTypes.string,
    headline3: PropTypes.string,
    date3: PropTypes.string,
    places3: PropTypes.string,
    // other
    language: PropTypes.string,
    season: PropTypes.string,
};

EventBoxList.defaultProps = {
    // item1
    itemType1: '', // events
    itemId1: '',
    imgSrc1: '/images/chris-holgersson-276772-unsplash.jpg',
    imgAlt1: 'Default alt text',
    imgTitle1: 'Default title text',
    headline1: 'BBQ auf der Furtschellas',
    date1: 'Mo |2. October 2018',
    places1: 'Furtschellas, Sils Maria',
    url1: '#',
    targetUrl1: '_self',
    // item2
    itemType2: '', // events
    itemId2: '',
    imgSrc2: '/images/chris-holgersson-276772-unsplash.jpg',
    imgAlt2: 'Default alt text',
    imgTitle2: 'Default title text',
    headline2: 'BBQ auf der Furtschellas',
    date2: 'Mo |2. October 2018',
    places2: 'Furtschellas, Sils Maria',
    url2: '#',
    targetUrl2: '_self',
    // item3
    itemType3: '', // events
    itemId3: '',
    imgSrc3: '/images/chris-holgersson-276772-unsplash.jpg',
    imgAlt3: 'Default alt text',
    imgTitle3: 'Default title text',
    headline3: 'BBQ auf der Furtschellas',
    date3: 'Mo | 2. October 2018',
    places3: 'Furtschellas, Sils Maria',
    url3: '#',
    targetUrl3: '_self',
    // other
    language: 'de',
    season: 'winter',
};

export default EventBoxList;
